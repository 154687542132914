import { Paths } from '../app/settings/models/enviroment-consts/paths';

export const environment = {
  production: true,
  SECURITY: 'commons.devbam.com/security/v1/auth', //legacy
  SECURITY_OIDC: 'commons.devbam.com/security/v1/auth', //security
  SITEKEYV2: '6LePU-YZAAAAAHOb7wLuXcPyHn91bblRgop19d0_',
  CONTENT_MANAGEMENT: `commons.devbam.com/security/v1/auth${Paths.CONTENT_MANAGEMENT}`,
  assets: 'https://assets.devbam.com/contenido_compartido/',
};
