import { Injectable } from '@angular/core';

import { default as jwt_decode } from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public token: string;
  public clientId: string;
  public username: string;
  public isMobile: boolean;
  public otp: string;
  public redirectURI: string;
  public state: string;
  public mfa_token: string;
  public nonce: string;
  public multiSessionToken: string;
  public captchaValue: string;
  public codeChallenge: string;
  public codeMethodChallenge: string;

  private readonly $keyPublic = new BehaviorSubject<string>(null);

  constructor() { }

  public setSessionData(token: string, isMobile?: boolean, otp?: string) {
    this.setToken(token);
    this.isMobile = isMobile === true;
    this.otp = otp;
  }

  public setToken(token: string) {
    this.token = token;
    const decoded = jwt_decode(token);
    this.clientId = decoded['aud'];
    this.username = decoded['sub'];
  }

  public setKeyPublic(key: string): void {
    this.$keyPublic.next(key);
  }

  public getKeyPublic(): string {
    return this.$keyPublic.value;
  }


}
