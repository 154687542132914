import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiGateway } from '../../../settings/models/enviroment-consts/api-gateway';
import { DataService } from '../data/data.service';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  constructor(
    private readonly http: HttpClient,
    private readonly dataService: DataService
  ) { }

  public changePassword(previousPassword: string, proposedPassword: string, captcha: string): Observable<any> {
    const url = `https://${ApiGateway.SECURITY}/users/password`;
    const httpOptions = {
      headers: new HttpHeaders({'Authorization': `bearer ${this.dataService.token}` })
    };
    const requestBody = {
      client_id: this.dataService.clientId,
      previousPassword,
      proposedPassword,
      'g-recaptcha-response': captcha

    };
    return this.http.put(url, requestBody, httpOptions);
  }

  public login(encryptedData: any): Observable<any> {
    const url = `https://${ApiGateway.SECURITY_OIDC}/authorize`;
    
    const requestBody = {
      encryptedData,
      grant_type: 'password',
      state: this.dataService.state,
      client_id: this.dataService.clientId,
      redirect_uri: this.dataService.redirectURI,
      response_type: 'code',
      'g-recaptcha-response' : this.dataService.captchaValue,
      nonce: this.dataService.nonce,
      code_challenge: this.dataService.codeChallenge,
      code_challenge_method: this.dataService.codeMethodChallenge
    };
    return this.http.post(url, requestBody);
  }

  public secondFactorAuth(otp: string): Observable<any> {
    let url = `https://${ApiGateway.SECURITY_OIDC}/token`;

    const requestBody = {
      client_id: this.dataService.clientId,
      mfa_token : this.dataService.mfa_token,
      grant_type: 'mfa_token',
      otp: otp,
    };
    return this.http.post(url, requestBody);
  }

  public multiSessionAuth(): Observable<any> {

    let url = `https://${ApiGateway.SECURITY_OIDC}/token`;

    const requestBody = {
      client_id: this.dataService.clientId,
      multisession_token : this.dataService.multiSessionToken,
      grant_type: 'multisession_token'
    };
    return this.http.post(url, requestBody);
  }

  /**
   * Validates if the provided token is valid. This means:
   * 1. The token was created by the expected source
   * 2. The token hasn't expired yet
   */
  public validateToken(): Observable<any> {
    const url = `${ApiGateway.SECURITY}/users/validate-password-recovery-token`;
    return this.http.post(url, { token: this.dataService.token });
  }

  public newPassword(newPassword: string): Observable<any> {
    const url = `${ApiGateway.SECURITY}/users/password`;
    const requestBody = {
      token: this.dataService.token,
      verificationCode: this.dataService.otp,
      newPassword
    };
    return this.http.post(url, requestBody);
  }

  /**
   * Servicio para obtener la llave publica para cifrar credenciales
   * @returns public key Observable<any>
   */
  getPublicKey(): Observable<any> {
    const url = `https://${ApiGateway.SECURITY}/legacy/publicKey`;
    return this.http.get(url);
  }

}
