<!-- <div *ngIf="isDev">
  <button
    [routerLink]="['/index', { lang: 'es', action: 'password_change' }]">Cambio de contraseña</button>
  <button
    [routerLink]="['/index', { lang: 'en', action: 'password_change' }]">Password change</button>
  <button
    [routerLink]="['/index', { lang: 'es', action: 'password_forgot', token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJncm91cHMiOltdLCJpc3MiOiJiYW5pc3Rtby5jb20iLCJzdWIiOiJsYUxvY2ExMiIsImF1ZCI6InN2cCIsIkNJUyI6NDgwMTExOTEsImVtYWlsIjpbIlRFU1RRQUJBTklTVE1PQEdNQUlMLkNPTSJdLCJwaG9uZV9udW1iZXIiOlsiMzkyMDk2OCJdLCJqdGkiOiJjNjY1NWU3ZC1jZTEwLTQ1NWEtYWY4YS0zYjgyZWVmMzNmNDgiLCJ0b2tlbl90eXBlIjoiQUNDRVNTX1RPS0VOIiwiaWF0IjoxNTUwMTU3OTIwLCJleHAiOjE1NTAxNjM5MjB9.ErdwbsMVLz0Iizu-DFCecYITbnRn_okOUKuvV4HCnqI', otp: '123456' }]">Recuperación de contraseña</button>
  <button
    [routerLink]="['/index', { lang: 'en', action: 'password_forgot', token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJncm91cHMiOltdLCJpc3MiOiJiYW5pc3Rtby5jb20iLCJzdWIiOiJsYUxvY2ExMiIsImF1ZCI6InN2cCIsIkNJUyI6NDgwMTExOTEsImVtYWlsIjpbIlRFU1RRQUJBTklTVE1PQEdNQUlMLkNPTSJdLCJwaG9uZV9udW1iZXIiOlsiMzkyMDk2OCJdLCJqdGkiOiJjNjY1NWU3ZC1jZTEwLTQ1NWEtYWY4YS0zYjgyZWVmMzNmNDgiLCJ0b2tlbl90eXBlIjoiQUNDRVNTX1RPS0VOIiwiaWF0IjoxNTUwMTU3OTIwLCJleHAiOjE1NTAxNjM5MjB9.ErdwbsMVLz0Iizu-DFCecYITbnRn_okOUKuvV4HCnqI', otp: '654321' }]">Password recovery</button>
  <button
    [routerLink]="['/index', { lang: 'es', action: 'login', client_id: 'svp', redirect_uri: 'https://www.google.com/login', response_type: 'code', state: 'any' }]">Login</button>
  <button routerLink="">Home</button>
  <button routerLink="/sidekicks">Go to NotFound</button>
</div> -->
<lib-main-app>
  <router-outlet></router-outlet>
  <lib-modal
    *ngIf="mModalService.getDisplayModal()"
    displayModal="mModalService.getDisplayModal()"
    option1="{{ mModalService.getLblOption1() }}"
    option2="{{ mModalService.getLblOption2() }}"
    btnOption1="{{ mModalService.getIsBtnOption1() }}"
    btnOption2="{{ mModalService.getIsBtnOption2() }}"
    image="{{ mModalService.getIsImage() }}"
    iconClass="{{ mModalService.getIconClass() }}"
    colorClass="{{ mModalService.getColorClass() }}"
    sourceImage="{{ mModalService.getSourceImage() }}"
    title="{{ mModalService.getTitle() }}"
    description="{{ mModalService.getDescription() }}"
    (onHideEvent)="mModalService.onHideEvent($event)"
    (option1Event)="mModalService.onOpt1Event($event)"
    (option2Event)="mModalService.onOpt2Event($event)"
  >
  </lib-modal>
</lib-main-app>
