import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// import { ContentManagementService } from '@com-bam/logic-lib';
import { ModalService } from '@com-bam/view-web-lib';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  constructor(private readonly translate: TranslateService, public mModalService: ModalService) { }

  
  title ='rdct_frt_wfu_newest';
  ngOnInit() {
    this.translate.setDefaultLang('es');
  }
}