import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { MessageModule } from 'primeng/message';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { StepsModule } from 'primeng/steps';
import { MenubarModule } from 'primeng/menubar';
import { TranslateModule } from '@ngx-translate/core';
import { ViewWebLibModule } from '@com-bam/view-web-lib';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ButtonModule,
    InputTextModule,
    CardModule,
    ToastModule,
    MessageModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    StepsModule,
    DialogModule,
    MenubarModule,
    ViewWebLibModule,
    ReactiveFormsModule
    // LoadingComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    ButtonModule,
    InputTextModule,
    CardModule,
    ToastModule,
    MessageModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    StepsModule,
    DialogModule,
    MenubarModule,
    TranslateModule,
  ],
})
export class SharedModule {}
